import { Controller } from "stimulus"
const SmoothScroll = require('smooth-scroll')

export default class extends Controller {
  static targets = ['anchor']
  static values = {
    url: String,
    lookup: String
  }

  connect() {
    const scroll = new SmoothScroll();
    const url = this.urlValue
    const lookup = this.lookupValue
    const anchor = this.anchorTarget

    if (url.includes(lookup)) {
      scroll.animateScroll(anchor, null, { updateURL: false, offset: 16 });
    }
  }
}
