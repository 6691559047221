import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['pin', 'pinReference']
  static values = {
    width: Number,
    pin: Boolean,
  }
  static classes = ['pinned', 'stuck']

  connect() {
    this.checkSize()
    this.checkPosition()
  }
  get windowWidth() {
    return window.innerWidth || document.documentElement.clientWidth
  }
  pinValueChanged() {
    if(this.pinValue === true) {
      this.element.classList.add(this.pinnedClass)
    } else {
      this.element.classList.remove(this.pinnedClass)
    }
  }
  get pinnedBox() {
    return this.pinTarget.getBoundingClientRect()
  }
  get referenceBottom() {
    return this.pinReferenceTarget.getBoundingClientRect()
  }

  checkPosition() {
    const pinnedPosition = this.referenceBottom.height - this.pinnedBox.height
    const referenceBottom = this.referenceBottom.bottom + window.scrollY
    const stopScrollPoint = window.scrollY + this.pinnedBox.height
    if(referenceBottom <= stopScrollPoint) {
      this.pinTarget.style.position = 'relative'
      this.pinTarget.style.top = `${pinnedPosition}px`
    } else {
      this.pinTarget.style.position = ''
      this.pinTarget.style.top = ''
    }
  }

  checkSize() {
    if(this.windowWidth >= this.widthValue) {
      this.pinValue = true
    } else {
      this.pinValue = false
    }
  }
}

